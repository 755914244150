<div class="footer-section">
  <div class="footer-image-container"  *ngIf="!hideTreeImage">
    <img class="footer-image" src="./assets/images/trees-image.png" alt="">
  </div>
  <div class="footer-section--main">
    <div class="footer-section--main__title">
      <span
        >Let's Chat! We are ready to tackle your next technical challenge.</span
      >
      <button (click)="navigate('/contact')" class="main-btn footer-contact-btn">CONTACT</button>
    </div>
    <hr class="horizontal-line" />
    <div class="footer-section--main__menu-container">
      <div class="flex">
        <img
          class="footer-logo"
          src="./assets/images/logo-light.png"
          alt=""
        />
        <div class="footer-menu">
          <a
            *ngFor="let item of footerItems"
            [routerLink]="item.link"
            class="footer-item-link"
            >{{ item.label }}</a
          >
        </div>
      </div>
      <div class="footer-text-container">
        <span class="footer-text">
          2022 // Created in Boulder, Colorado.
        </span>
      </div>
    </div>
  </div>
</div>
