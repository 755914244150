<div class="main-menu-container">
  <div>
    <img
      class="main-menu-container__logo"
      src="./assets/images/logo-black.png"
      alt=""
    />
    <ul class="main-menu-container--list">
      <li
        *ngFor="let item of navigationMenu"
        class="main-menu-container--list-item"
      >
        <a
          class="main-menu-container--list__link"
          [routerLink]="item?.link"
          routerLinkActive="menu-active-link"
          (click)="closeMenu()"
          ><span class="main-menu-container--list__link-text">{{ item?.label }}</span></a
        >
      </li>
    </ul>
  </div>
  <div class="flex flex-direction-column flex-align-item-center close-btn-container">
    <button
      class="menu-container--btn main-menu-container__close-btn"
      (click)="closeMenu()"
    >
      <div
        class="menu-container--btn__icon menu-container--btn__close-icon"
      ></div>
    </button>
    <span class="main-menu-container__text">{{menuText}}</span>
  </div>
</div>
