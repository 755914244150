import { FooterComponent } from './footer/footer.component';
import { WorkItemComponent } from './work-item/work-item.component';
import { TechnicalExperienceComponent } from './technical-experience/technical-experience.component';
import { DeliveryMethodComponent } from './delivery-method/delivery-method.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { ContactFormComponent } from './contact-form/contact-form.component';

export const SHARED_COMPONENTS = [
  FooterComponent,
  WorkItemComponent,
  TechnicalExperienceComponent,
  DeliveryMethodComponent,
  HeaderComponent,
  MenuComponent,
  ContactFormComponent,
];

export * from './footer/footer.component';
export * from './work-item/work-item.component';
export * from './technical-experience/technical-experience.component';
export * from './delivery-method/delivery-method.component';
export * from './header/header.component';
export * from './menu/menu.component';
export * from './contact-form/contact-form.component';
