<mat-sidenav-container  (backdropClick)="close()">
  <mat-sidenav #sidenav (keydown.escape)="close()" position="end" method="push">
    <app-menu></app-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="main-content" [class.overflow-hidden]="isMenuOpened">
      <router-outlet></router-outlet>
      <app-footer [hideTreeImage]="hideTreeImage"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
