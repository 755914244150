<div class="video-background-container">
  <video #video autoplay loop (canplay)="video.play()"
         (loadedmetadata)="video.muted = true" >
    <source src="https://storage.googleapis.com/radiant-dev-assets/flatiron.mov" />
  </video>
  <div class="home-main-container">
    <button class="home-main-container__btn" (click)="navigate('/contact')">say hello</button>
    <div class="menu-container">
      <button class="menu-container--btn" (click)="openMenu()">
        <div class="menu-container--btn__icon menu-container--btn__icon-light"></div>
      </button>
      <div class="menu-container--items">
        <a class="menu-container--items__link" *ngFor="let item of menuItems" [routerLink]="item.link">{{
          item.label
          }}</a>
      </div>
    </div>
    <div class="home-main-section">
      <div>
        <img class="home-main-section__logo" [src]="homeLogo" alt="" />
        <p class="home-main-section__title">
          {{ homeTitle }}
        </p>
      </div>
      <div class="home-main-section__line"></div>
    </div>
  </div>
</div>
<app-delivery-method [data]="deliveryMethodData"></app-delivery-method>
<app-technical-experience
  [data]="technicalExperienceData"
></app-technical-experience>
<div class="work-section">
  <div class="work-list-container">
    <app-work-item *ngFor="let item of workItems" [work]="item"></app-work-item>
  </div>
</div>
